<template>
  <div class="login-view">
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },

  methods: {
  },

  created () {
    let token = this.$router.currentRoute.query.token
    console.log(token)
    console.log(this.$cookie)
    if (token) {
    //   this.$cookie.set('token', token, '3m')
    //   this.$cookie.set('isHoldToken', 'true')
    //   console.log(this.$cookie.get('token'))

      this.$store.commit('user/updateToken', token)
      console.log(this.$store.state.user.token)
    }
    this.$router.push({
      name: 'siteuserindex'
    })
    // if (this.isHoldToken) {
    //   this.$router.push({
    //     name: 'home'
    //   })
    // } else {
    //   this.getCaptcha()
    // }
  }
}
</script>

<style lang="scss">
  .login-view {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;

    .login-bg {
      width: 70%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .login-right {
      width: 30%;
    }

    .content-wrapper {
      width: 350px;
      margin: 0 auto;
      text-align: center;
      padding: calc((100vh - 470px) * 0.3) 0 0;
    }

    .app-name {
      font-size: 30px;
      color: #1a1a1a;
      margin: 20px 0 80px;
    }

    .flex-row .el-form-item__content {
      display: flex;

      img {
        margin-left: 20px;
        height: 36px;
      }
    }

    .submit-button {
      margin-top: 40px;
      width: 100%;
      background: #e70012;
      color: #fff;
    }
  }
</style>
